<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-loading="state.loaderEnabled">
        <div class="card-header border-0 pt-6">
          <el-divider content-position="left"><h2>Liste des réseaux</h2></el-divider>
          <SearchAndFilter
              v-if="state.initialMyList && !state.loaderEnabled"
              :listInitial="state.initialMyList"
              :filters="tableFilters"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
              :key="state.reloadFilter"
            ></SearchAndFilter>

          
        </div>
        <div class="card-body pt-0">
            
      
          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="table-small-td cursor-pointer"
            @click-function="displayNetwork($event)"
          >

            <template v-slot:cell-name="{ row: data }"><div class="mx-2 text-dark">{{ data.name }}</div></template>

            <template v-slot:cell-productTypes="{ row: data }">
              <el-tag size="large" class="mx-1" v-for="(product, i) in data.productTypes" effect="light" :key="i">{{ product.charAt(0).toUpperCase()  + product.slice(1) }}</el-tag>
            </template>

            <template v-slot:cell-devices="{ row: data }">
                <div v-for="(device, i) in data.devices" :key="i" class="fs-7">{{ (device.status=="online" ? "🟩" : "🟥") }} {{ device.model }}</div>
            </template>

            <template v-slot:cell-appliance_ip="{ row: data }">
                <div class="fs-7 text-gray-700">{{ data.appliance_ip }}</div>
                <div class="fs-7">{{ data.subnet }}</div>
            </template>
            
            <template v-slot:cell-tags="{ row: data }">
              <el-tag size="large" class="mx-1" v-for="(tag, i) in data.tags" effect="plain" :key="i">{{ tag }}</el-tag>
            </template>

            <template v-slot:cell-id>
              <span class="fs-2">🔎</span>
            </template>

          </Datatable>          


        </div>
      </div>
    </transition>
    <DisplayNetwork @deleteNetwork="deleteNetwork()" :idNetwork="state.currentNetwork.id" :key="state.openNetwork" />
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import DisplayNetwork from "@/views/still/meraki/displayNetwork.vue";
  

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter, DisplayNetwork},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [],
        loadingDatatable: 0,
        drawer : false,
        openNetwork: 0,
        currentNetwork: {id: 0} as any,
        firewall : [] as any,
        reloadFilter : 0,
    });

      const tableHeader = ref([
        { name: "Réseau", key: "name", sortable: false},
        { name: "Périphériques", key: "devices", sortable: false,},
        { name: "Produits", key: "productTypes", sortable: false,},
        { name: "IP / Subnet", key: "appliance_ip", sortable: false,},
        { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
        { name: "", key: "id", sortable: false, className:"w-125px"},
      ]);


      const tableFilters = ref([
        { name: "Réseau", key: "name", label: "name", },
      ]);


      onMounted(async () => {  
        if (!store.getters.getOrg.idOrg) return false;
        buildList();
      });

      const buildList = async () => {

        state.loaderEnabled = true;

        const mNetworks = await getAxios("/networks?organization_id=" + store.getters.getOrg.idOrg);

        state.myList = mNetworks;
        const organizationDevicesStatuses = await getAxios("/organizationDevicesStatuses?organization_id=" + store.getters.getOrg.idOrg);
                
        state.myList.forEach(element => {
          element.devices = organizationDevicesStatuses.filter((item: any) =>item.networkId == element.id);
        });

        state.initialMyList = state.myList;

        state.loaderEnabled = false;
        
      }

      const deleteNetwork = async () => {
        console.log(state.currentNetwork.id,state.initialMyList);
        const delnetInit = state.initialMyList.filter((item: any) =>item.id != state.currentNetwork.id);
        state.initialMyList = delnetInit;
        const delnet = state.myList.filter((item: any) =>item.id != state.currentNetwork.id);
        state.myList = delnet;
        state.loadingDatatable += 1;
        state.reloadFilter += 1;
      };


      const displayNetwork = async (data) => {
        state.currentNetwork.id = data.id;
        state.openNetwork +=  1;
      };

      const openUrl = (url) => {
        window.open(url,'_blank', 'width=1024,height=1024');
      };
  
      return {
        state,
        store,
        tableHeader,
        tableFilters,
        displayNetwork,
        openUrl,
        buildList,
        deleteNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>