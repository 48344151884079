
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import DisplayNetwork from "@/views/still/meraki/displayNetwork.vue";
  

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter, DisplayNetwork},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [],
        loadingDatatable: 0,
        drawer : false,
        openNetwork: 0,
        currentNetwork: {id: 0} as any,
        firewall : [] as any,
        reloadFilter : 0,
    });

      const tableHeader = ref([
        { name: "Réseau", key: "name", sortable: false},
        { name: "Périphériques", key: "devices", sortable: false,},
        { name: "Produits", key: "productTypes", sortable: false,},
        { name: "IP / Subnet", key: "appliance_ip", sortable: false,},
        { name: "Tags", key: "tags", sortable: false, className:"hide-small"},
        { name: "", key: "id", sortable: false, className:"w-125px"},
      ]);


      const tableFilters = ref([
        { name: "Réseau", key: "name", label: "name", },
      ]);


      onMounted(async () => {  
        if (!store.getters.getOrg.idOrg) return false;
        buildList();
      });

      const buildList = async () => {

        state.loaderEnabled = true;

        const mNetworks = await getAxios("/networks?organization_id=" + store.getters.getOrg.idOrg);

        state.myList = mNetworks;
        const organizationDevicesStatuses = await getAxios("/organizationDevicesStatuses?organization_id=" + store.getters.getOrg.idOrg);
                
        state.myList.forEach(element => {
          element.devices = organizationDevicesStatuses.filter((item: any) =>item.networkId == element.id);
        });

        state.initialMyList = state.myList;

        state.loaderEnabled = false;
        
      }

      const deleteNetwork = async () => {
        console.log(state.currentNetwork.id,state.initialMyList);
        const delnetInit = state.initialMyList.filter((item: any) =>item.id != state.currentNetwork.id);
        state.initialMyList = delnetInit;
        const delnet = state.myList.filter((item: any) =>item.id != state.currentNetwork.id);
        state.myList = delnet;
        state.loadingDatatable += 1;
        state.reloadFilter += 1;
      };


      const displayNetwork = async (data) => {
        state.currentNetwork.id = data.id;
        state.openNetwork +=  1;
      };

      const openUrl = (url) => {
        window.open(url,'_blank', 'width=1024,height=1024');
      };
  
      return {
        state,
        store,
        tableHeader,
        tableFilters,
        displayNetwork,
        openUrl,
        buildList,
        deleteNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  