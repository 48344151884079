
  import { defineComponent, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Vlans from "@/views/still/meraki/vlans.vue";
  import Firewall from "@/views/still/meraki/firewall.vue";
  
  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Vlans, Firewall},
    props: {
      idNetwork: Number,
    },
    emits: ["deleteNetwork"],
    setup(props, {emit}) {

      const store = useStore();
      const state = reactive({
        currentDel : 0,
        currentNetwork: {} as any,
          drawer: false,
      });

      onMounted(async () => {  
        if (!props.idNetwork) return false;
        const mNetwork = await getAxios("/network?network_id=" + props.idNetwork);
        state.currentNetwork = mNetwork
        
        state.drawer = true;
      });

      const openUrl = (url) => {
          window.open(url,'_blank', 'width=1024,height=1024');
      };

      const delNetwork = async (data) => {
        state.currentDel = 1;  
        await getAxios("/delNetwork?network_id=" + data.id);  

        setTimeout(() => {
          emit("deleteNetwork", 1);
            state.drawer = false;
        }, 3500);

      };
     
      return {
        state,
        store,
        openUrl,
        delNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  