
  import { defineComponent, onMounted, reactive } from "vue";
  import { useStore } from "vuex";

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    props: {
      idNetwork: String,
    },
    setup(props) {

    const store = useStore();
    const state = reactive({
        loaderEnabled: true,
        gVlans: [] as any,
        gNetwork: [] as any,
        loadingDatatable: 0,
    });

    onMounted(async () => {  
      if (!store.getters.getOrg.idOrg) return false;
      buildList();
    });

    const buildList = async () => {

        const mVlans = await getAxios("/vlans?network_id=" + props.idNetwork);

        const mNetwork = await getAxios("/network?network_id=" + props.idNetwork);

        const organizationDevicesStatuses = await getAxios("/organizationDevicesStatuses?organization_id=" + store.getters.getOrg.idOrg);
        mNetwork.devices = organizationDevicesStatuses.filter((item: any) =>item.networkId == mNetwork.id);

        state.gVlans = mVlans.reponse;
        state.gNetwork = mNetwork;
        state.loaderEnabled = false;
        
      }
     
      return {
        state,
        store
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  