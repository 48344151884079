<template>
    <el-drawer direction='rtl' v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp">
      
      <div class="card shadow-none" style="width: 100%; height: 100%;">
        <div class="card-header" id="kt_activities_header" style="background: white;">
          
          <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
            <button v-if="state.currentDel != 1" @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              Détail du réseau
            </h1>
          </div>

          
          <div class="d-flex align-items-center m-0">
            
            <span class="btn btn-light-warning fs-4 text-dark text-hover-white cursor-pointer me-8" @click="openUrl(state.currentNetwork.url)">Afficher sur la dashboard Meraki</span>

            <el-popconfirm v-if="state.currentNetwork.tags.includes('Auto_deploy')" title="Validez-vous la suppression du réseau ?"     confirm-button-text="Oui, le supprimer"  cancel-button-text="Non, annuler la suppression" @confirm="delNetwork(state.currentNetwork)">
              <template #reference>
                <span class="btn btn-light-danger fs-4 text-danger text-hover-white cursor-pointer me-8">Décommissionner le réseau</span>
              </template>
            </el-popconfirm>
            
          </div>

        </div>
        <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background-size: cover;">

          <el-alert v-if="state.currentDel == 1"  title="Suppression en cours..." description="Suppression du réseau en cours, veuillez patientez svp..." show-icon type="error" class="mt-4 fs-2 mb-4"/>

          <el-row :gutter="20" v-if="state.currentDel != 1">
            <el-col :span="6"><div class="grid-content" />
              <Vlans :idNetwork="state.currentNetwork.id" :key="state.currentNetwork.id" />
            </el-col>
            <el-col :span="18"><div class="grid-content" />
              <Firewall :idNetwork="state.currentNetwork.id" :key="state.currentNetwork.id" :editMode="false" />
            </el-col>
          </el-row>
            
        </div>
      </div>
    </el-drawer>
</template>
  
  <script lang="ts">
  import { defineComponent, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Vlans from "@/views/still/meraki/vlans.vue";
  import Firewall from "@/views/still/meraki/firewall.vue";
  
  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Vlans, Firewall},
    props: {
      idNetwork: Number,
    },
    emits: ["deleteNetwork"],
    setup(props, {emit}) {

      const store = useStore();
      const state = reactive({
        currentDel : 0,
        currentNetwork: {} as any,
          drawer: false,
      });

      onMounted(async () => {  
        if (!props.idNetwork) return false;
        const mNetwork = await getAxios("/network?network_id=" + props.idNetwork);
        state.currentNetwork = mNetwork
        
        state.drawer = true;
      });

      const openUrl = (url) => {
          window.open(url,'_blank', 'width=1024,height=1024');
      };

      const delNetwork = async (data) => {
        state.currentDel = 1;  
        await getAxios("/delNetwork?network_id=" + data.id);  

        setTimeout(() => {
          emit("deleteNetwork", 1);
            state.drawer = false;
        }, 3500);

      };
     
      return {
        state,
        store,
        openUrl,
        delNetwork
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>