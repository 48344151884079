<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-loading="state.loaderEnabled">
        <div class="card-header border-0 pt-6">
          <el-divider content-position="left"><h2>Réseau : {{ state.gNetwork.name }}</h2></el-divider>         
        </div>
        <div class="card-body pt-0">
          <el-form label-width="120px" style="max-width: 600px">

            <el-form-item label="Type">
              <el-tag size="large" class="mx-1" v-for="(product, i) in state.gNetwork.productTypes" effect="light" :key="i">{{ product.charAt(0).toUpperCase()  + product.slice(1) }}</el-tag>
            </el-form-item>

            <el-form-item label="Tags">
              <el-tag size="large" class="mx-1" v-for="(tag, i) in state.gNetwork.tags" effect="plain" :key="i">{{ tag }}</el-tag>
            </el-form-item>

            <br>
            <el-divider content-position="left"><h2>Matériel(s)</h2></el-divider>

            <template v-for="(device, i) in state.gNetwork.devices" :key="i">
              <el-form-item label="Modèle">
                <span><b>{{ device.model }}</b></span>
              </el-form-item> 

              <el-form-item label="Nom" v-if="device.name">
                <span>{{ device.name }}</span>
              </el-form-item> 

              <el-form-item label="MAC">
                <span>{{ device.mac }}</span>
              </el-form-item> 

              <el-form-item label="N° de Série">
                <span>{{ device.serial }}</span>
              </el-form-item> 

              <el-form-item label="Statut">
                <span>{{ device.status }}</span>
              </el-form-item>       
            </template>


            <br>

            <el-divider content-position="left"><h2>VLANs</h2></el-divider>
            
            <template v-for="(vlan, i) in state.gVlans" :key="i" class="mx-2">
              <br>
              <h4 class="mx-2">🔹{{ vlan.name }}</h4>

              <el-form-item label="IP">
                <span><b>{{ vlan.applianceIp }}</b></span>
              </el-form-item>  
              

              <el-form-item label="ID">
                <span>{{ vlan.id }}</span>
              </el-form-item>  

              <el-form-item label="Subnet">
                <span>{{ vlan.subnet }}</span>
              </el-form-item>  

              <el-form-item label="DHCP Handling">
                <span>{{ vlan.dhcpHandling }}</span>
              </el-form-item>  

              <el-form-item label="DHCP Servers">
                  <template v-for="(dhcpRelayServerIp, i) in vlan.dhcpRelayServerIps" effect="light" :key="i">{{ dhcpRelayServerIp }}</template>
              </el-form-item>  
              
            </template>


          </el-form>

           

        </div>
      </div>
    </transition>

  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, reactive } from "vue";
  import { useStore } from "vuex";

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    props: {
      idNetwork: String,
    },
    setup(props) {

    const store = useStore();
    const state = reactive({
        loaderEnabled: true,
        gVlans: [] as any,
        gNetwork: [] as any,
        loadingDatatable: 0,
    });

    onMounted(async () => {  
      if (!store.getters.getOrg.idOrg) return false;
      buildList();
    });

    const buildList = async () => {

        const mVlans = await getAxios("/vlans?network_id=" + props.idNetwork);

        const mNetwork = await getAxios("/network?network_id=" + props.idNetwork);

        const organizationDevicesStatuses = await getAxios("/organizationDevicesStatuses?organization_id=" + store.getters.getOrg.idOrg);
        mNetwork.devices = organizationDevicesStatuses.filter((item: any) =>item.networkId == mNetwork.id);

        state.gVlans = mVlans.reponse;
        state.gNetwork = mNetwork;
        state.loaderEnabled = false;
        
      }
     
      return {
        state,
        store
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>